import "./src/styles/global.scss";

export const onRouteUpdate = ({ location }, { offset = 0 }) => {
  //
};

export const onInitialClientRender = () => {
  /* script for scroll position */
  const debounce = (fn) => {
    let frame;
    return (...params) => {
      if (frame) {
        cancelAnimationFrame(frame);
      }
      frame = requestAnimationFrame(() => {
        fn(...params);
      });
    }
  };
  
  const storeScroll = () => {
    document.documentElement.dataset.scroll = window.scrollY;
    if (window.scrollY > 100) {
      document.documentElement.dataset.documentPosition = 'page';
    }
    else {
      document.documentElement.dataset.documentPosition = 'start';
    }
    
  };
    
  document.addEventListener('scroll', debounce(storeScroll), { passive: true });
  
  storeScroll();
  
};
